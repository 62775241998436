import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import styles from './styles.module.scss';

import ROUTES from 'routes';
import { useAppSelector } from 'store';
import authSlice from 'store/auth.slice';

const AuthLayout: FC = () => {
  const token = useAppSelector((state) => state[authSlice.name].access);

  if (token) {
    return <Navigate to={ROUTES.REPORTS} />;
  }

  return (
    <section className={styles['login-container']}>
      <figure className={styles['login-wallpaper']} />
      <Outlet />
    </section>
  );
};

export default AuthLayout;
