import { useState } from 'react';

import { useCreateCandidateMutation } from 'store/users.api';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
};
export default function useCreateCandidateForm() {
  const [createCandidate, { isLoading }] = useCreateCandidateMutation();
  const [formState, changeFormState] = useState<{ firstName: string; lastName: string; email: string }>({
    ...initialState,
  });

  const onSubmit = () => {
    createCandidate({
      first_name: formState.firstName,
      last_name: formState.lastName,
      email: formState.email,
    })
      .unwrap()
      .then(() => {
        changeFormState({ ...initialState });
      });
  };

  const onChange = (key: string, value: string) => {
    changeFormState((state) => ({ ...state, [key]: value }));
  };

  const isDisabled = !formState.firstName || !formState.lastName || !formState.email || isLoading;

  return {
    formState,
    isDisabled,
    onChange,
    onSubmit,
  };
}
