export const API_ENDPOINTS = {
  ASSESSMENTS: {
    INTERVIEWS: '/assessments/interviews',
    INTERVIEW: (id: string) => `/assessments/interviews/${id}`,
    INTERVIEW_COMPARE: (id: string, id2: string) => `/assessments/interviews/${id}/compare/${id2}`,
    INTERVIEW_DEPARTMENTS: (id: string) => `/assessments/interviews/${id}/departments`,
    INTERVIEW_EPICS: (id: string) => `/assessments/interviews/${id}/epics`,
    INTERVIEW_PDF: (id: string) => `/assessments/interviews/${id}/pdf`,
  },
  QUESTIONS: {
    DEPARTMENTS: '/questions/departments',
    DEPARTMENT: (id: string) => `/questions/departments/${id}`,
    EPICS: '/questions/epics',
    EPIC: (id: string) => `/questions/epics/${id}`,
    QUESTIONS: '/questions/questions',
    QUESTION: (id: string) => `/questions/questions/${id}`,
  },
  USERS: {
    CANDIDATES: '/users/candidates',
    CANDIDATE: (id: string) => `/users/candidates/${id}`,
    GOOGLE_AUTH: `/users/google-auth`,
  },
};
