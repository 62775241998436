import { BaseQueryApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

import type { Authorisation } from '../types';

import { resetTokens, setTokens } from './auth.slice';
import { REDUCER_AUTH_SLICE } from './constants';
import { API_ENDPOINTS } from './endpoints';

import type { RootState } from './index';

const baseUrl = process.env.REACT_APP_API_URL;

const query = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const { access = '' } = (getState() as RootState)[REDUCER_AUTH_SLICE];

    if (access) {
      headers.set('authorization', `Bearer ${access}`);
    }

    return headers;
  },
});

const mutex = new Mutex();

const extendedQuery = async (args: FetchArgs, api: BaseQueryApi, extraOptions: object) => {
  await mutex.waitForUnlock();

  // TODO: remove with refresh endpoint functionality
  // eslint-disable-next-line prefer-const
  let result = await query(args, api, extraOptions);

  if ([API_ENDPOINTS.USERS.GOOGLE_AUTH].some((req) => result.meta?.request.url.includes(req)) && !result?.error) {
    // is token retrieve request

    api.dispatch(setTokens(result.data as Authorisation));
  }

  // const isLoginRequest = result.meta?.request.url.endsWith(API_ENDPOINTS.USERS.GOOGLE_AUTH);
  // const { refresh = '' } = (api.getState() as RootState)[REDUCER_AUTH_SLICE];

  // if (!isLoginRequest && refresh && result.error && result.error.status === 401) {
  //   if (!mutex.isLocked()) {
  //     const release = await mutex.acquire();
  //     try {
  //       const refreshResult = await query(
  //         {
  //           url: API_ENDPOINTS.AUTH.REFRESH_TOKEN,
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: {
  //             refresh,
  //           },
  //         },
  //         api,
  //         extraOptions,
  //       );

  //       if (refreshResult.data && !refreshResult?.error) {
  //         api.dispatch(setTokens(refreshResult.data));
  //         result = await query(args, api, extraOptions);
  //       } else {
  //         api.dispatch(resetTokens());
  //       }
  //     } finally {
  //       release();
  //     }
  //   } else {
  //     await mutex.waitForUnlock();

  //     result = await query(args, api, extraOptions);
  //   }
  // }

  // TODO:
  // We don't have an refresh endpoint yet so we're only clearing token, for now
  // uncomment upper and delete if, when endpoint will be ready
  if (result.error && result.error.status === 401) {
    api.dispatch(resetTokens());
  }

  return result;
};

export default extendedQuery;
