import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import { AuthState, Authorisation } from '../types';

import { REDUCER_AUTH_SLICE } from './constants';

const authPersistConfig = {
  key: REDUCER_AUTH_SLICE,
  storage,
};

const initialState: AuthState = {
  refresh: '',
  access: '',
};

const authSlice = createSlice({
  name: REDUCER_AUTH_SLICE,
  initialState,
  reducers: {
    setTokens(state, { payload }: PayloadAction<Authorisation>) {
      state.access = payload.access_token;
      state.refresh = payload.refresh_token;
    },
    resetTokens() {
      return { ...initialState };
    },
  },
});

export const { setTokens, resetTokens } = authSlice.actions;

export default {
  ...authSlice,
  reducer: persistReducer(authPersistConfig, authSlice.reducer),
};
