import { useId } from 'react';
import RSelect, { Props as SelectProps } from 'react-select';

import './styles.scss';

type ISelect = {
  label?: string;
};

const Select = ({ label = '', ...restProps }: ISelect & SelectProps) => {
  const id = useId();
  return (
    <div className="form-component">
      {label && (
        <label htmlFor={id} className="label">
          {label}
        </label>
      )}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <RSelect hideSelectedOptions className="r-select" classNamePrefix="r-select" {...restProps} />
    </div>
  );
};
export default Select;
