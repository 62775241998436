import { CredentialResponse, GoogleLogin } from '@react-oauth/google';

import { useGoogleAuthMutation } from 'store/profile.api';

export default function GoogleAuthButton() {
  const [googleAuth] = useGoogleAuthMutation();
  const onTokenRecieved = (creds: CredentialResponse) => {
    if (creds?.credential) googleAuth({ token: creds.credential });
  };

  return <GoogleLogin hosted_domain="gowombat.team" onSuccess={onTokenRecieved} />;
}
