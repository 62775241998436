import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from './baseQuery';
import { REDUCER_PROFILE_API } from './constants';
import { API_ENDPOINTS } from './endpoints';

import { IAuthUser, IUserReg } from 'types';

export const profileApi = createApi({
  reducerPath: REDUCER_PROFILE_API,
  baseQuery,
  endpoints: (builder) => ({
    googleAuth: builder.mutation<IAuthUser, IUserReg>({
      query: (candidate) => ({
        method: 'POST',
        url: API_ENDPOINTS.USERS.GOOGLE_AUTH,
        body: candidate,
      }),
    }),
  }),
});

export const { useGoogleAuthMutation } = profileApi;
