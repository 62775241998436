import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';

import App from './App';
import './index.scss';

const history = createBrowserHistory();
const app = document.getElementById('root') as HTMLElement;

if (app) {
  // eslint-disable-next-line no-restricted-globals
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  const root = createRoot(app);

  root.render(<App />);
}
