import { ChangeEvent, InputHTMLAttributes, useState } from 'react';
import Form from 'react-bootstrap/Form';

import { IQuestion } from 'types';

import './styles.scss';

interface IQuestionBox
  extends Omit<IQuestion, /* epic isn't required here */ 'epic'>,
    InputHTMLAttributes<HTMLTextAreaElement> {
  // Reassigned cause IQuestion, and HTMLTextAreaElement have same fields
  id: string;
  title: string;
  onRangeChange(val: string): void;
}

const QuestionBox = ({ title, text, level, id, onRangeChange, ...restProps }: IQuestionBox) => {
  const [value, setValue] = useState<string>('0');
  const onRangeValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onRangeChange(e.target.value);
  };

  return (
    <div className="question-box form-component">
      {title && (
        <label className="label" htmlFor={id}>
          {title}
          {text && <p>{text}</p>}
        </label>
      )}
      <Form.Range
        className="question-box__range"
        step={1}
        value={value}
        min={0}
        max={10}
        onChange={onRangeValueChange}
      />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <textarea className="input" id={id} {...restProps} />
    </div>
  );
};

export default QuestionBox;
