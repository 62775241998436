import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import WombatScore, { WombatScoreValue } from 'components/UI/WombatScore';
import { useGetInterviewQuery } from 'store/assessments.api';

import './styles.scss';

export default function ReportPage() {
  const { assessmentId } = useParams();
  const { data: interview } = useGetInterviewQuery(assessmentId || '', { skip: !assessmentId });

  const onPrint = () => {
    window.print();
  };

  useEffect(() => {
    document.title = `Review ${interview?.candidate.email}, ${new Date(
      interview?.created_at || '',
    ).toLocaleString()} | Gowombat Assessment Tool`;
  }, []);

  return (
    <main id={`report-${assessmentId}`}>
      <div className="container">
        <div className="no-print row d-flex justify-content-end mb-3">
          <div className="col-3 d-flex justify-content-end">
            <button type="button" onClick={onPrint} className="btn btn-sm">
              Print
            </button>
          </div>
        </div>
      </div>
      <div id="report-printable" className="report-page container">
        <div className="row dash">
          <div className="col-6">
            <h3 className="report-page__candidate">
              Candidate: <br />
              <span>
                {interview?.candidate.first_name} {interview?.candidate.last_name}
                <br /> ({interview?.candidate.email})
              </span>
            </h3>
          </div>
          {interview?.created_at && (
            <div className="col-6">
              <h3 className="text-end">
                Date: <br />
                <span>{new Date(interview.created_at).toLocaleString()}</span>
              </h3>
            </div>
          )}
        </div>
        {interview?.assessment.map((assessment) => {
          return (
            <div key={assessment.question.id} className="row dash mb-3 mt-3">
              <div className="col-9">
                <h3 className="mb-2">{assessment?.question?.title || 'Question'}</h3>
                <p>{assessment.comment}</p>
              </div>
              <div className="col-3 report-page__assessment-question-rate">
                <div>
                  <h3>Rate</h3>
                </div>
                <div>
                  <WombatScore value={assessment.score as WombatScoreValue} />
                </div>
              </div>
            </div>
          );
        })}
        <div className="row">
          <div className="col-9" />
          <div className="col-3 mb-3">
            <h3 className="text-end">
              Average score: <br />
              <WombatScore value={Math.round(interview?.avg_score as number) as unknown as WombatScoreValue} />
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-6" />
          <div className="col-6">
            <h3 className="text-end">
              Interviewer: <br />
              <span>
                {interview?.interviewer.first_name} {interview?.interviewer.last_name} <br /> (
                {interview?.interviewer.email})
              </span>
            </h3>
          </div>
        </div>
      </div>
    </main>
  );
}
