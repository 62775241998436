import { Navigate, Outlet } from 'react-router-dom';

import Header from '../Header';

import styles from './styles.module.scss';

import ROUTES from 'routes';
import { useAppSelector } from 'store';
import authSlice from 'store/auth.slice';

export default function PageLayout() {
  const token = useAppSelector((state) => state[authSlice.name].access);

  if (!token) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper} />
      <Header />
      <Outlet />
    </div>
  );
}
