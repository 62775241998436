import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from './baseQuery';
import { REDUCER_ASSESSMENTS_API } from './constants';
import { API_ENDPOINTS } from './endpoints';

import { IAssessment, IFullReview, IReviews } from 'types';

export const assessmentsApi = createApi({
  reducerPath: REDUCER_ASSESSMENTS_API,
  baseQuery,
  tagTypes: ['Asessments', 'Assessment'],
  endpoints: (builder) => ({
    createAssessment: builder.mutation<null, IAssessment>({
      query: (assessment) => ({
        method: 'POST',
        url: API_ENDPOINTS.ASSESSMENTS.INTERVIEWS,
        body: assessment,
      }),
      invalidatesTags: ['Asessments'],
    }),
    getInterviews: builder.query<IReviews[], void>({
      query: () => ({
        url: API_ENDPOINTS.ASSESSMENTS.INTERVIEWS,
      }),
    }),
    getInterview: builder.query<IFullReview, string>({
      query: (id) => ({
        url: API_ENDPOINTS.ASSESSMENTS.INTERVIEW(id),
      }),
    }),
    compareInterviews: builder.query<{}, { id: string; id2: string }>({
      query: ({ id, id2 }) => ({
        url: API_ENDPOINTS.ASSESSMENTS.INTERVIEW_COMPARE(id, id2),
      }),
    }),
    /* getInterviewDepartments: builder.query<{}, string>({
      query: (id) => ({
        url: API_ENDPOINTS.ASSESSMENTS.INTERVIEW_DEPARTMENTS(id),
      }),
    }), */
    /* getInterviewEpics: builder.query<{}, string>({
      query: (id) => ({
        url: API_ENDPOINTS.ASSESSMENTS.INTERVIEW_EPICS(id),
      }),
    }), */
    /* getInterviewPdf: builder.query<{}, string>({
      query: (id) => ({
        url: API_ENDPOINTS.ASSESSMENTS.INTERVIEW_PDF(id),
      }),
    }), */
  }),
});

export const {
  useGetInterviewsQuery,
  useGetInterviewQuery,
  useCompareInterviewsQuery,
  /* useGetInterviewDepartmentsQuery, */
  /* useGetInterviewEpicsQuery, */
  /*  useGetInterviewPdfQuery, */
  useCreateAssessmentMutation,
} = assessmentsApi;
