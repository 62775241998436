import { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import ROUTES from 'routes';
import { useGetInterviewsQuery } from 'store/assessments.api';

export default function ReportListPage() {
  const navigate = useNavigate();
  const { data: interviews } = useGetInterviewsQuery();

  useEffect(() => {
    document.title = 'Assessments | Gowombat Assessment Tool';
  }, []);

  return (
    <main className="container">
      <div className="card">
        <Table striped responsive>
          <thead>
            <tr>
              <th>Interviewer</th>
              <th>Candidate</th>
              <th>Avg score</th>
              <th>Taken at</th>
            </tr>
          </thead>
          <tbody>
            {interviews?.map((interview) => (
              <tr key={interview.id} onClick={() => navigate(ROUTES.REPORT_BY_ID(interview.id))}>
                <td>
                  {interview.interviewer?.first_name} {interview.interviewer?.last_name} ({interview.interviewer?.email}
                  )
                </td>
                <td>
                  {interview.candidate?.first_name} {interview.candidate?.last_name} ({interview.candidate?.email})
                </td>
                <td>{interview.avg_score}</td>
                <td>{new Date(interview.created_at).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </main>
  );
}
