import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import QuestionBox from 'components/QuestionBox';
import ROUTES from 'routes';
import { useCreateAssessmentMutation } from 'store/assessments.api';
import { useGetQuestionsQuery } from 'store/questions.api';
import { useGetCandidatesQuery } from 'store/users.api';
import { Answer, IQuestion } from 'types';

export default function AssessmentForm({ candidate, epics }: { candidate: string; epics: string }) {
  const navigate = useNavigate();
  const [createAssessment] = useCreateAssessmentMutation();
  const { data: questions } = useGetQuestionsQuery({ epics });
  const { data: candidateData } = useGetCandidatesQuery(undefined, {
    selectFromResult: (response) => ({ ...response, data: response?.data?.find((can) => can?.id === candidate) }),
  });
  const [answers, setAnswers] = useState<Answer[] | null>(null);

  useEffect(() => {
    if (questions?.length) {
      setAnswers(questions?.map((question) => ({ question: question?.id, comment: '', score: '0' } as Answer)));
    }
  }, [questions]);

  const onChange = (id: string, key: string) => (val: string) => {
    if (answers?.length) {
      const newAnswers = answers?.map((answer) => {
        if (answer.question === id) {
          return {
            ...answer,
            [key]: val,
          };
        }
        return answer;
      });
      setAnswers(newAnswers);
    }
  };

  const onCreateAssessment = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    if (candidateData?.id && answers?.length) {
      createAssessment({
        candidate: candidateData?.id,
        assessments: answers,
      })
        .unwrap()
        .then(() => navigate(ROUTES.REPORTS));
    }
  };

  return (
    <main className="container">
      <h2 className="text-center">
        You are reviewing candidate: {candidateData?.first_name} {candidateData?.last_name}
      </h2>
      <form className="row card" onSubmit={onCreateAssessment}>
        {questions?.map((question: IQuestion) => (
          <QuestionBox
            key={question.id}
            onRangeChange={onChange(question.id, 'score')}
            onChange={(evt) => onChange(question.id, 'comment')(evt.target.value)}
            id={question.id}
            title={question.title}
            text={question.text}
            level={question.level}
          />
        ))}
        <div className="row d-flex justify-content-center">
          <div className="button-container">
            <button type="submit" className="btn">
              Submit
            </button>
          </div>
        </div>
      </form>
    </main>
  );
}
