import { GoogleOAuthProvider } from '@react-oauth/google';

import GoogleAuthButton from './Google';
import styles from './styles.module.scss';

export default function LoginPage() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT}>
      <div className={styles.authContainer}>
        <h1 className={styles.title}>Welcome!</h1>
        <GoogleAuthButton />
      </div>
    </GoogleOAuthProvider>
  );
}
