import { InputHTMLAttributes, useId } from 'react';

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  customStyles?: string;
  label?: string;
  errors?: string;
  styleLabel?: string;
}

const Input = ({
  type = 'text',
  customStyles = '',
  label = '',
  errors = '',
  styleLabel = '',
  ...restProps
}: IInput) => {
  const id = useId();
  const InputElement = 'input';
  const classNames = ['input', errors && 'input__error', customStyles].join(' ');
  const InputProps = restProps;
  return (
    <div className="form-component">
      {label && (
        <label htmlFor={id} className={['label', styleLabel].join(' ')}>
          {label}
        </label>
      )}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <InputElement id={id} type={type} className={classNames} {...InputProps} />
      {errors && <div className="errors">{errors}</div>}
    </div>
  );
};
export default Input;
