import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import useCreateCandidateForm from './useCreateCandidateForm';

import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import Select from 'components/Select';
import { useGetDepartmentsQuery, useGetEpicsQuery } from 'store/questions.api';
import { useGetCandidatesQuery } from 'store/users.api';

import './styles.scss';

export default function CreateReviewForm() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedDepartments, setSelectedDepartments] = useState<string>('');
  const [selectedEpics, setSelectedEpics] = useState<string[]>([]);
  const [selectedCandidate, setSelectedCandidate] = useState<string>('');

  const { data: candidates } = useGetCandidatesQuery();
  const { data: departments } = useGetDepartmentsQuery();
  const { data: epics } = useGetEpicsQuery(undefined, {
    selectFromResult: (response) => {
      return {
        ...response,
        data:
          response?.data?.filter((epic) =>
            selectedDepartments ? epic.department.some((department) => department.id === selectedDepartments) : true,
          ) || [],
      };
    },
  });
  const { onChange, onSubmit: onCreateCandidate, isDisabled, formState } = useCreateCandidateForm();
  const isReviewDisabled = !selectedEpics.length || !selectedCandidate;

  const onDepartmentChange = (newValue: any) => {
    setSelectedDepartments(newValue?.value ? newValue?.value : '');
  };

  const onEpicsChange = (newValue: any) => {
    setSelectedEpics(newValue.map((value: { label: string; value: string }) => value.value));
  };

  const createReview = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    setSearchParams({
      candidate: selectedCandidate,
      epics: selectedEpics.join(','),
    });
  };

  return (
    <form className="card" onSubmit={createReview}>
      <h2 className="text-center">Please select candidate or provide information about new one</h2>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-6">
              <Input
                type="text"
                label="First Name"
                value={formState.firstName}
                onChange={(evt) => onChange('firstName', evt.target.value)}
              />
            </div>
            <div className="col-6">
              <Input
                type="text"
                label="Last Name"
                value={formState.lastName}
                onChange={(evt) => onChange('lastName', evt.target.value)}
              />
            </div>
          </div>

          <Input
            type="email"
            label="Email"
            value={formState.email}
            onChange={(evt) => onChange('email', evt.target.value)}
          />
          <div className="row d-flex justify-content-center mb-3">
            <div className="button-container">
              <button type="button" className="btn" disabled={isDisabled} onClick={onCreateCandidate}>
                Submit
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 review-form__candidates-container">
          <div className="d-md-none">
            <p className="text-center">OR</p>
          </div>
          {candidates?.map((candidate) => (
            <div className="checkbox-container" key={candidate.id} id={candidate.id}>
              <Checkbox
                onChange={(evt) => setSelectedCandidate(evt.target.value)}
                value={candidate.id}
                name={candidate.id}
                checked={candidate.id === selectedCandidate}
              >
                {candidate.email}
              </Checkbox>
            </div>
          ))}
        </div>
      </div>
      <h2 className="text-center">Please select departments and epics you want to review</h2>
      <div className="row">
        <div className="col-12 col-md-6 mb-3">
          <Select
            isClearable
            label="Departments"
            closeMenuOnSelect={false}
            onChange={onDepartmentChange}
            options={departments?.map((department) => ({ label: department.name, value: department.id }))}
          />
        </div>
        <div className="col-12 col-md-6 mb-3">
          <Select
            label="Epics"
            closeMenuOnSelect={false}
            isMulti
            onChange={onEpicsChange}
            options={epics?.map((epic) => ({ label: epic.name, value: epic.id }))}
          />
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="button-container">
          <button type="submit" className="btn" disabled={isReviewDisabled}>
            Submit
          </button>
        </div>
      </div>
    </form>
  );
}
