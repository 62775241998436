import { useSearchParams } from 'react-router-dom';

import AssessmentForm from './Assessment';
import CreateReviewForm from './Form';

export default function CreateReportPage() {
  const [searchParams] = useSearchParams();

  const candidate = searchParams.get('candidate') || '';
  const epics = searchParams.get('epics') || '';
  const isReview = candidate && epics;

  return (
    <main className="container">
      {isReview && <AssessmentForm candidate={candidate} epics={epics} />}
      {!isReview && <CreateReviewForm />}
    </main>
  );
}
