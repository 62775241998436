import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import AuthLayout from 'layouts/AuthLayout';
import PageLayout from 'layouts/Page';
import LoginPage from 'pages/Login';
import CreateReportPage from 'pages/Reports/Create';
import ReportPage from 'pages/Reports/Item';
import ReportListPage from 'pages/Reports/List';
import ROUTES from 'routes';
import { persistor, store } from 'store';

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            <Route element={<PageLayout />}>
              <Route path={ROUTES.REPORTS} element={<ReportListPage />} />
              <Route path={ROUTES.REPORT_CREATE} element={<CreateReportPage />} />
              <Route path={ROUTES.REPORT_BY_ID(':assessmentId')} element={<ReportPage />} />
              <Route path="*" element={<Navigate to={ROUTES.REPORTS} replace />} />
            </Route>
            <Route element={<AuthLayout />}>
              <Route path={ROUTES.LOGIN} element={<LoginPage />} />
            </Route>
            <Route path="*" element={<Navigate to={ROUTES.LOGIN} replace />} />
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
