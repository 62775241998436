import classNames from 'classnames';
import { FC, ImgHTMLAttributes } from 'react';

import LogoGreenIcon from '../../assets/svg/logo_2.svg';
import LogoWhiteIcon from '../../assets/svg/Logo_start.svg';

export enum IconType {
  LogoGreen = 'LogoGreen',
  LogoWhite = 'LogoWhite',
}

const IconByType = {
  [IconType.LogoGreen]: LogoGreenIcon,
  [IconType.LogoWhite]: LogoWhiteIcon,
};

interface IIcons extends ImgHTMLAttributes<HTMLImageElement> {
  type: IconType;
}

const Icons: FC<IIcons> = ({ type, ...imageProps }) => (
  <img
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...imageProps}
    alt={imageProps?.alt || type}
    className={classNames(imageProps.className, 'icon')}
    src={IconByType[type]}
  />
);

export default Icons;
