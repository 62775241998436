import { InputHTMLAttributes, useId } from 'react';

import styles from './styles.module.scss';

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  errors?: string;
}

const Checkbox = ({ errors = '', type = 'checkbox', children, ...restProps }: IInput) => {
  const id = useId();
  return (
    <>
      <label htmlFor={id} className={[styles.form__label, errors && styles['is-error']].join(' ')}>
        <input
          id={id}
          className={styles.form__label__input}
          type={type}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...restProps}
        />
        <span className={[styles.form__label__box, errors && styles['is-error']].join(' ')}>
          <span className={styles.form__label__name}>{children}</span>
        </span>
      </label>
      {errors && <div className="errors">{errors}</div>}
    </>
  );
};

export default Checkbox;
