import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from './baseQuery';
import { REDUCER_USERS_API } from './constants';
import { API_ENDPOINTS } from './endpoints';

import { IUser, ICandidate } from 'types';

export const usersApi = createApi({
  reducerPath: REDUCER_USERS_API,
  baseQuery,
  tagTypes: ['Candidates', 'Candidate'],
  endpoints: (builder) => ({
    getCandidates: builder.query<ICandidate[], void>({
      query: () => ({
        url: API_ENDPOINTS.USERS.CANDIDATES,
      }),
      providesTags: ['Candidates'],
    }),
    getCandidate: builder.query<ICandidate, string>({
      query: (id) => ({
        url: API_ENDPOINTS.USERS.CANDIDATE(id),
      }),
      providesTags: ['Candidate'],
    }),
    createCandidate: builder.mutation<IUser, IUser>({
      query: (candidate) => ({
        method: 'POST',
        url: API_ENDPOINTS.USERS.CANDIDATES,
        body: candidate,
      }),
      invalidatesTags: ['Candidates'],
    }),
  }),
});

export const { useGetCandidatesQuery, useGetCandidateQuery, useCreateCandidateMutation } = usersApi;
