import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from './baseQuery';
import { REDUCER_QUESTIONS_API } from './constants';
import { API_ENDPOINTS } from './endpoints';

import { IDepartment, IEpic, IQuestion } from 'types';

export const questionsApi = createApi({
  reducerPath: REDUCER_QUESTIONS_API,
  baseQuery,
  tagTypes: ['Departments', 'Epics', 'Questions'],
  endpoints: (builder) => ({
    getDepartments: builder.query<IDepartment[], void>({
      query: () => ({
        url: API_ENDPOINTS.QUESTIONS.DEPARTMENTS,
      }),
      providesTags: ['Departments'],
    }),
    /* getDepartment: builder.query<IDepartment, string>({
      query: (id) => ({
        url: API_ENDPOINTS.QUESTIONS.DEPARTMENT(id),
      }),
    }), */
    getEpics: builder.query<IEpic[], void>({
      query: () => ({
        url: API_ENDPOINTS.QUESTIONS.EPICS,
      }),
      providesTags: ['Epics'],
    }),
    /* getEpic: builder.query<IEpic, string>({
      query: (id) => ({
        url: API_ENDPOINTS.QUESTIONS.EPIC(id),
      }),
    }), */
    getQuestions: builder.query<IQuestion[], { epics: string }>({
      query: ({ epics }) => ({
        url: API_ENDPOINTS.QUESTIONS.QUESTIONS,
        params: {
          epic_ids: epics,
        },
      }),
      providesTags: ['Questions'],
    }),
    /* getQuestion: builder.query<IQuestion, string>({
      query: (id) => ({
        url: API_ENDPOINTS.QUESTIONS.QUESTION(id),
      }),
    }), */
  }),
});

export const {
  useGetDepartmentsQuery,
  /* useGetDepartmentQuery, */
  useGetEpicsQuery,
  /* useGetEpicQuery, */
  useGetQuestionsQuery,
  /* useGetQuestionQuery, */
} = questionsApi;
